import {
    Borgona2,
    Borgona3,
    Borgona8,
    Borgona9,
    Borgona10,
    Borgona11,
    Borgona12,
    Borgona13,
    BorgonaModal2,
    BorgonaModal3,
    BorgonaModal8,
    BorgonaModal9,
    BorgonaModal10,
    BorgonaModal11,
    BorgonaModal12,
    BorgonaModal13,
    SeparadorXL,
    RhinFlauta750SC,
    RhinFlauta750SCModal,
    XClass,
    XClassModal
} from "../images/productos/botella_vino/borgona"

import {
    PDF2,
    PDF3,
    PDF8,
    PDF9,
    PDF10,
    PDF11,
    PDF12,
    PDF13,
    PDFRhinFlauta750SC,
    PDFXClass
} from "../../static/08-borgona/index"

import { BorgonaEco } from "./borgona_eco"

export const Borgona = [
    {
        pdfDownload: PDFRhinFlauta750SC,
        size: 0,
        name: "Rhin Flauta 750 ",
        src: RhinFlauta750SC,
        srcModal: RhinFlauta750SCModal,
        color: {
            white: true,
            green: true,
            darkGreen: false,
            yellow: false,
            lightGreen: false,
        },
    },
    {
        pdfDownload: PDF3,
        size: 0,
        name: "Rhin Flauta Verde",
        src: Borgona3,
        srcModal: BorgonaModal3,
        color: {
            white: true,
            green: true,
            darkGreen: false,
            yellow: false,
            lightGreen: false,
        },
    },
    {
        pdfDownload: PDF8,
        size: 0,
        name: "",

        src: Borgona8,
        srcModal: BorgonaModal8,
        color: {
            white: true,
            green: true,
            darkGreen: false,
            yellow: false,
            lightGreen: false,
        },
    }, {
        pdfDownload: PDF9,
        size: 0,
        name: "",

        src: Borgona9,
        srcModal: BorgonaModal9,
        color: {
            white: true,
            green: true,
            darkGreen: false,
            yellow: false,
            lightGreen: false,
        },
    }, {
        pdfDownload: PDF10,
        size: 0,
        name: "",

        src: Borgona10,
        srcModal: BorgonaModal10,
        color: {
            white: true,
            green: true,
            darkGreen: false,
            yellow: false,
            lightGreen: false,
        },
    }, {
        pdfDownload: PDF13,
        size: 0,
        name: "",

        src: Borgona13,
        srcModal: BorgonaModal13,
        color: {
            white: true,
            green: true,
            darkGreen: false,
            yellow: false,
            lightGreen: false,
        },
    },
    {
        pdfDownload: PDFXClass,
        size: 0,
        name: "",
        src: XClass,
        srcModal: XClassModal,
        color: {
            white: true,
            green: true,
            darkGreen: false,
            yellow: false,
            lightGreen: false,
        },
    },
    /// borgona eco
    ...BorgonaEco,
    { src: SeparadorXL, divider: true },
    // {
    //     pdfDownload: PDF11,
    //     size: 0,
    //     name: "",
    //     src: Borgona11,
    //     srcModal: BorgonaModal11,
    //     color: {
    //         white: true,
    //         green: true,
    //         darkGreen: false,
    //         yellow: false,
    //         lightGreen: false,
    //     },
    // }, 
    {
        pdfDownload: PDF12,
        size: 0,
        name: "",
        src: Borgona12,
        srcModal: BorgonaModal12,
        color: {
            white: true,
            green: true,
            darkGreen: false,
            yellow: false,
            lightGreen: false,
        },
    },]