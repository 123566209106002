import React from "react";

import ProductCard from "../../../components/ProductCards/ProductCards";
import SEO from "../../../components/SEO/Seo";

import { Borgona } from "../../../data/borgona";

import WineBottle from "../botellas_vino";

const BorgonaView = () => {
  return (
    <>
      <SEO
        title="Blue Sky SA - Botellas de Vidrio. Borgoña"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio , Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  - Botellas de vidrio . Borgoña, Rhoden, Rielsling, Rhin, Flauta o Jerezana. Alta, baja, con picada, sin picada. 187, 250, 375, 500 y 750 ml. Color verde, ambar, marrón y blanco. Boca corcho, tapón sintético o screw caps. Mendoza - Argentina."
        description=" Encuentra botellas borgoña y borgoña eco de vidrio en Blue Sky SA.  - Botellas de vidrio . Borgoña, Rhoden, Rielsling, Rhin, Flauta o Jerezana. Alta, baja, con picada, sin picada. 187, 250, 375, 500 y 750 ml. Color verde, ambar, marrón y blanco. Boca corcho, tapón sintético o screw caps. Mendoza - Argentina."
      />
      <WineBottle subtitle="Borgoña">
        <ProductCard data={Borgona} />
      </WineBottle>
    </>
  );
};

export default BorgonaView;
